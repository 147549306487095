import React, { useState, useEffect, useContext, Fragment } from "react";
import { AuthContext } from "../context/AuthContext";

const Login = () => {
  const { isLoggedIn, login, loginOTPs } = useContext(AuthContext);

  const [mobileNumber, setmobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isLoggedIn) {
      window.location.href = "/";
    }
  }, [isLoggedIn]);

  const handlemobileNumberChange = (e) => setmobileNumber(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleLogin = async () => {
    try {
      const response = await login({ mobileNumber, password });

      if (response.success) {
        // Successful login
        setSuccessMessage(response.data.message);
        setErrorMessage("");
        setShowOtpBox(false);
        // Redirect or perform further actions here
      } else if (response.requiresOtp) {
        // OTP required
        setShowOtpBox(true);
        setErrorMessage(response.message || "OTP required. Please check your inbox.");
        setSuccessMessage("");
      } else {
        // Other failure cases
        setErrorMessage(response.message || "Login failed. Please try again.");
        setSuccessMessage("");
      }
    } catch (error) {
      if (error.response?.data?.requiresOtp) {
        // OTP required during error handling
        setShowOtpBox(true);
        setErrorMessage(error.response.data.message || "OTP required. Please check your inbox.");
        setSuccessMessage("");
      } else {
        // General error handling
        setErrorMessage(error.response?.data?.message || "An error occurred. Please try again.");
        setSuccessMessage("");
      }
    }
  };


  const handleOtpVerification = async () => {
    try {
      const response = await loginOTPs({ mobileNumber, otp });

      if (response.data.success) {
        setSuccessMessage("OTP verified. Logging you in...");
        setErrorMessage("");
        window.location.href = "/";
      } else {
        setErrorMessage(response.data.message);
        setSuccessMessage("");
      }
    } catch (error) {
      setErrorMessage("OTP verification failed. Please try again.");
      setSuccessMessage("");
    }
  };

  return (
    <Fragment>
      <div className="main-wrap" style={{ backgroundColor: "#5271ff" }}>
        {/* Navigation Header */}
        <div className="nav-header bg-transparent shadow-none border-0">
          <div className="nav-top w-100 d-flex justify-content-between align-items-center">
            {/* Logo */}
            <a href="/" aria-label="Go to homepage" className="d-flex align-items-center">
              <i className="feather-camera text-current display1-size me-2 ms-0"></i>
              <span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">
                tapcoe
              </span>
            </a>

            {/* Buttons */}
            <div className="d-flex">
              <a
                href="/login"
                className="header-btn bg-dark fw-500 text-white font-xsss px-4 py-2 ms-2 text-center lh-20 rounded-xl"
              >
                Login
              </a>
              <a
                href="/register"
                className="header-btn bg-current fw-500 text-white font-xsss px-4 py-2 ms-2 text-center lh-20 rounded-xl"
              >
                Register
              </a>
            </div>
          </div>
        </div>


        {/* Login Form Section */}
        <div className="row">
          <div
            className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
            style={{ backgroundImage: `url("assets/images/loginpic.png")` }}
          ></div>
          <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                <h2 className="fw-700 display1-size display2-md-size mb-4">
                  Login <br /> your account
                </h2>
                <form>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-mobile text-grey-500 pe-0"></i>
                    <input
                      type="text"
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Whatsapp Number / Email"
                      value={mobileNumber}
                      onChange={handlemobileNumberChange}
                    />
                  </div>
                  {!showOtpBox && (
                    <div className="form-group icon-input mb-3">
                      <i className="font-sm ti-key text-grey-500 pe-0"></i>
                      <input
                        type="password"
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                    </div>
                  )}
                  {showOtpBox && (
                    <div className="form-group icon-input mb-3">
                      <i className="font-sm ti-key text-grey-500 pe-0"></i>
                      <input
                        type="text"
                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      <button
                        type="button"
                        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 mt-2"
                        onClick={handleOtpVerification}
                      >
                        Log In With  OTP
                      </button>
                    </div>
                  )}
                  <div className="col-sm-12 p-0 text-left">
                    <div className="form-group mb-1">
                      <button
                        type="button"
                        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                        onClick={handleLogin}
                      >
                        {!showOtpBox ? "Login" : "Resend OTP"}
                      </button>
                    </div>
                    {successMessage && (
                      <div className="alert alert-success mt-3">
                        {successMessage}
                      </div>
                    )}
                    {errorMessage && (
                      <div className="alert alert-danger mt-3">
                        {errorMessage}
                      </div>
                    )}
                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                      Don't have an account?{" "}
                      <a href="/register" className="fw-700 ms-1">
                        Register
                      </a>
                    </h6>
                    <h6 className="text-grey-500 font-xsss fw-500 mt-2 mb-0 lh-32">
                      <a href="/forgot" className="fw-700 ms-1">
                        Forgot Password?
                      </a>
                    </h6>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
