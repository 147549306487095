import React, { useState, Fragment ,useContext} from "react";
//import { resendOTP, verifyUserOTP } from "../api"; // Adjust path as per your project
import { verifyForget, forgotPassword } from "../api/authApi";
const Forgot = () => {

  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [step, setStep] = useState(1); // Step: 1 = enter email/phone, 2 = verify OTP, 3 = reset password

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "emailOrPhone") setEmailOrPhone(value);
    if (name === "otp") setOtp(value);
    if (name === "newPassword") setNewPassword(value);
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    try {
      await forgotPassword({ mobileNumber: emailOrPhone });
      alert("OTP sent successfully! Please check your WhatsApp.");
      setStep(2); // Move to OTP verification step
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert(error.response.data.message);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        otp,
        mobileNumber: emailOrPhone,
        newPassword,
      };
      await verifyForget(payload); // Send OTP + New Password
      alert("Password changed successfully!");
      window.location.href = "/login";// Move to confirmation step or login page
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert(error.response?.data?.message || "Failed to verify OTP.");
    }
  };
  

  const handleChangePassword = (e) => {
    e.preventDefault();
    console.log("Change password to:", newPassword);
    alert("Password changed successfully!");
    // Add logic to update password via API if needed
  };

  return (
    <Fragment>
      <div className="main-wrap">
        <div className="nav-header bg-transparent shadow-none border-0">
          <div className="nav-top w-100">
            <a href="/" aria-label="Go to homepage">
              <i className="feather-camera text-current display1-size me-2 ms-0"></i>
              <span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">
                tapcoe
              </span>
            </a>
            <a href="/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
            <a href="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a>
          </div>
        </div>

        <div className="row">
          <div
            className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
            style={{ backgroundImage: `url("assets/images/loginpic.png")` }}
          ></div>

          <div
            className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden"
            style={{
              background: "rgba(128, 128, 128, 0.7)",
              backdropFilter: "blur(8px)",
              borderRadius: "10px",
            }}
          >
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div
                className="card-body rounded-0 text-left"
                style={{
                  position: "relative",
                  zIndex: 1,
                  background: "rgba(255, 255, 255, 0.7)",
                  backdropFilter: "blur(10px)",
                  borderRadius: "10px",
                  padding: "20px",
                }}
              >
                {step === 1 && (
                  <>
                    <h2 className="fw-700 display1-size display2-md-size mb-4">
                      Enter your Number/Email
                    </h2>
                    <form onSubmit={handleSendOtp}>
                      <div className="form-group icon-input mb-3">
                        <input
                          type="text"
                          name="emailOrPhone"
                          className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                          placeholder="Number / Email"
                          value={emailOrPhone}
                          onChange={handleInputChange}
                        />
                        <i className="font-sm ti-mobile text-grey-500 pe-0"></i>
                      </div>
                      <button
                        type="submit"
                        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                      >
                        Send OTP
                      </button>
                    </form>
                  </>
                )}

{step === 2 && (
  <>
    <h2 className="fw-700 display1-size display2-md-size mb-4">
      Verify OTP & Set New Password
    </h2>
    <form onSubmit={handleVerifyOtp}>
      <div className="form-group icon-input mb-3">
        <input
          type="text"
          name="otp"
          className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
          placeholder="Enter OTP"
          value={otp}
          onChange={handleInputChange}
        />
        <i className="font-sm ti-key text-grey-500 pe-0"></i>
      </div>

      <div className="form-group icon-input mb-3">
        <input
          type="password"
          name="newPassword"
          className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
          placeholder="New Password"
          value={newPassword}
          onChange={handleInputChange}
        />
        <i className="font-sm ti-lock text-grey-500 pe-0"></i>
      </div>

      <button
        type="submit"
        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
      >
        Verify OTP & Change Password
      </button>
    </form>
  </>
)}

              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Forgot;
