import React, { Component, Fragment } from "react";
import axios from "axios"; // Import Axios
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Copyright from "../components/Copyright";
class Hostevent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      contactNumber: '',
      organization: '',
      eventType: '',
      eventDetails: '',
      message: '',
    };
  }

  // Handle input change
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  // Handle form submission
  handleSubmit = async (e) => {
    e.preventDefault();
    const { email, contactNumber, organization, eventType, eventDetails } = this.state;

    try {
      const response = await axios.post('https://api.picvot.com/api/v1/hostevent', {
        email,
        contactNumber,
        organization,
        eventType,
        eventDetails,
      });
      this.setState({ message: response.data.message });
    } catch (error) {
      console.error('Error hosting event:', error);
      this.setState({ message: 'Failed to host the event.' });
    }
  };

  render() {
    const { message } = this.state;

    return (
      <Fragment>
        <Header />
        <Leftnav />
        {/* <Rightchat /> */}

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <div
                    className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center"
                    style={{
                      backgroundImage: `url("/assets/images/help-bg.png")`,
                    }}
                  >
                    <div
                      className="card-body p-md-5 p-4 text-center"
                      style={{ backgroundColor: "rgba(0,85,255,0.8)" }}
                    >
                      <h2 className="fw-700 display2-size text-white display2-md-size lh-2">
                        Welcome to the tapcoe!
                      </h2>
                    </div>
                  </div>

                  <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
                    <div className="card-body">
                      <h5 className="fw-600 mb-3" style={{ color: 'rgba(0,85,255,0.8)' }}>
                        Connect for Hosting an Event
                      </h5>
                     
                      <form onSubmit={this.handleSubmit}>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label" style={{ color: 'rgba(0,85,255,0.8)' }}>
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter your email"
                            value={this.state.email}
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="mb-3">
                          <label htmlFor="contactNumber" className="form-label" style={{ color: 'rgba(0,85,255,0.8)' }}>
                            Contact Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="contactNumber"
                            placeholder="Enter your contact number"
                            value={this.state.contactNumber}
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="mb-3">
                          <label htmlFor="organization" className="form-label" style={{ color: 'rgba(0,85,255,0.8)' }}>
                            Organization
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="organization"
                            placeholder="Enter your organization"
                            value={this.state.organization}
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="mb-3">
                          <label htmlFor="eventType" className="form-label" style={{ color: 'rgba(0,85,255,0.8)' }}>
                            Type of Event
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="eventType"
                            placeholder="Enter the type of event"
                            value={this.state.eventType}
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="mb-3">
                          <label htmlFor="eventDetails" className="form-label" style={{ color: 'rgba(0,85,255,0.8)' }}>
                            Event Details
                          </label>
                          <textarea
                            className="form-control"
                            id="eventDetails"
                            rows="3"
                            placeholder="Enter event details"
                            value={this.state.eventDetails}
                            onChange={this.handleChange}
                          ></textarea>
                        </div>
                        {message && <div className="alert alert-info">{message}</div>}
                        <div className="d-grid gap-2">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                </div>
                <div className="mt-2">

                               <Copyright  />
                               </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter />
      </Fragment>
    );
  }
}

export default Hostevent;
