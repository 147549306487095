import React, { Component, Fragment } from "react";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Copyright from "../components/Copyright";
class Termandcondition extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <Leftnav />
                {/* <Rightchat /> */}

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row justify-content-center">
                                <div className="col-xl-10">
                                    <div className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center"
                                         style={{backgroundImage: `url("assets/images/help-bg.png")`}}>
                                        <div className="card-body p-md-5 p-4 text-center"
                                             style={{backgroundColor: 'rgba(0,85,255,0.8)'}}>
                                            <h2 className="fw-700 display2-size text-white display2-md-size lh-2">Welcome to the tapcoe!</h2>
                                        </div>
                                    </div>

                                    <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
                                        <div className="card-body">
                                        <div className="mb-4">
  <h5 className="fw-600 mb-3">TERMS AND CONDITIONS</h5>

  <h6 className="fw-600">Introduction</h6>
  <p>
    These Website Standard Terms and Conditions written on this webpage shall manage your use of this website. These Terms will be applied fully and affect your use of this Website. By using this Website, you agree to accept all terms and conditions written here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.
  </p>

  <h6 className="fw-600">Intellectual Property Rights</h6>
  <p>
    Other than the content you own, under these Terms, tapcoe.com and/or its licensors own all the intellectual property rights and materials contained on this Website. You are granted a limited license only for the purposes of viewing the material contained on this Website.
  </p>

  <h6 className="fw-600">Restrictions</h6>
  <p>
    You are specifically restricted from all of the following:
  </p>
  <p>
    - Selling, sublicensing, and/or otherwise commercializing any Website material;<br />
    - Publicly performing and/or showing any Website material;<br />
    - Using this Website in any way that is or may be damaging to this Website;<br />
    - Using this Website in any way that impacts user access to this Website;<br />
    - Using this Website contrary to applicable laws and regulations or in any way that may cause harm to the Website or to any person or business entity;<br />
    - Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this Website;<br />
    - Using this Website to engage in any advertising or marketing.
  </p>
  <p>
    Certain areas of this Website are restricted from being accessed by you, and tapcoe.com may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential, and you must maintain confidentiality as well.
  </p>

  <h6 className="fw-600">Your Content</h6>
  <p>
    In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video text, images, or other material you choose to display on this Website. By displaying Your Content, you grant tapcoe.com a non-exclusive, worldwide irrevocable, sublicensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media. Your Content must be your own and must not be invading any third-party’s rights. tapcoe.com reserves the right to remove any of Your Content from this Website at any time without notice.
  </p>

  <h6 className="fw-600">No Warranties</h6>
  <p>
    This Website is provided “as is,” with all faults, and tapcoe.com expresses no representations or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.
  </p>

  <h6 className="fw-600">Limitation of Liability</h6>
  <p>
    In no event shall tapcoe.com, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract. tapcoe.com, including its officers, directors, and employees, shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this Website.
  </p>

  <h6 className="fw-600">Indemnification</h6>
  <p>
    You hereby indemnify to the fullest extent tapcoe.com from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms.
  </p>

  <h6 className="fw-600">Severability</h6>
  <p>
    If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
  </p>

  <h6 className="fw-600">Variation of Terms</h6>
  <p>
    tapcoe.com is permitted to revise these Terms at any time as it sees fit, and by using this Website, you are expected to review these Terms on a regular basis.
  </p>

  <h6 className="fw-600">Assignment</h6>
  <p>
    tapcoe.com is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
  </p>

  <h6 className="fw-600">Entire Agreement</h6>
  <p>
    These Terms constitute the entire agreement between tapcoe.com and you in relation to your use of this Website and supersede all prior agreements and understandings.
  </p>

  <h6 className="fw-600">Governing Law & Jurisdiction</h6>
  <p>
    These Terms will be governed by and interpreted in accordance with the laws of India, and you submit to the non-exclusive jurisdiction of the state and federal courts located in India for the resolution of any disputes.
  </p>
</div>


                                       
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2">

<Copyright  />
</div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popupchat />
                <Appfooter />
            </Fragment>
        );
    }
}

export default  Termandcondition ;
