import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Copyright from "../components/Copyright";
import { getEvents } from "../api/eventsApi";
import Countdown from "../components/CountDown";
import SEO from "../components/Seo";
const Event = ({match}) => {
    const { slug = "public" } = match.params;
    console.log(slug);
    <SEO
    title={`Event  - Participate & Win | PicVot`}
    description={`Join event  on tapcoe and stand a chance to win exciting prizes!`}
    url={`https://picvot.com/events`}
    image={`https://tapcoe.com/assets/og-image.webp`}
  />
    const [eventList, setEventList] = useState([]);

    useEffect(() => {
        const fetchEventData = async () => {
            try {
                const response = await getEvents(slug); // Replace with your actual API endpoint
                setEventList(response);
            } catch (error) {
                console.error("Error fetching event data:", error);
            }
        };

        fetchEventData();
    }, []);

    const formatDate = (creationDate) => {
        const date = new Date(creationDate);
        const month = date.toLocaleString("en-US", { month: "short" }).toUpperCase(); // 3-letter month in uppercase
        const day = String(date.getDate()).padStart(2, "0"); // Day padded with '0' if it's a single digit
        return { month, day };
    };

    return (
        <Fragment>
            <Header />
            <Leftnav />
            {/* <Rightchat /> */}

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            {eventList.map((value, index) => {
                                const { month, day } = formatDate(value.creationDate || new Date()); // Ensure a valid date.
                                const joinedUserPics = value.joinedUserPics || []; // Handle undefined or empty array safely.

                                return (
                                    <div key={index} className="col-lg-4 col-md-6 pe-2 ps-2">
                                        <div className="card p-1 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl mb-3 overflow-hidden">
                                        <div
                style={{
                  position: "relative",
                  top: "-5px",
                  textAlign: "center",
                  fontSize: "12px",
                  color: "#6c757d",
                  padding: "5px 0", // Adjust padding to remove extra space
                }}
              >
                <Countdown expiryDate={value.expiryDate} />
              </div>
                                            <div
                                                className="card-image"
                                                style={{
                                                    width: "100%",
                                                    height: "200px",
                                                    overflow: "hidden",
                                                    borderRadius: "8px",
                                                    backgroundColor: "#1a1a2e",
                                                    position: "relative",
                                                }}
                                            >
                                                <img
                                                    src={`${value.poster}`}
                                                    alt="event"
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                    }}
                                                />

                                                {/* Overlay status badge */}
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: "0px",
                                                        right: "0px",
                                                        color: "#fff",
                                                        backgroundColor: "blue",
                                                        padding: "2px 4px",
                                                        borderRadius: "2px",
                                                        textTransform: "uppercase", // Make text uppercase
                                                        fontWeight: "bold",
                                                        fontSize: "5px",
                                                    }}
                                                >
                                                    {value.type}
                                                </div>
                                                {/* Prize overlay at bottom */}
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        bottom: "0px",
                                                        left: "0px",
                                                        width: "100%",
                                                        backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background
                                                        color: "white",
                                                        padding: "5px 10px",
                                                        fontSize: "12px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <i className="ti-gift me-2"></i>
                                                    <span>Prize: {value.prizeonewords || "Not specified"}</span>
                                                </div>
                                            </div>

                                            <div className="card-body d-flex ps-0 pe-0 pb-0">
                                                <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg">
                                                    <h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0">
                                                        <span className="ls-3 d-block font-xsss text-grey-500 fw-500">
                                                            {month}
                                                        </span>
                                                        {day}
                                                    </h4>
                                                </div>
                                                <h2 className="fw-700 lh-3 font-xss">
                                                    {value.title}
                                                    <span
                                                        className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            const { latitude, longitude } = value.location || {};
                                                            if (latitude && longitude) {
                                                                window.open(`https://www.google.com/maps?q=${latitude},${longitude}`, '_blank');
                                                            } else {
                                                                alert('Location coordinates not available.');
                                                            }
                                                        }}
                                                    >
                                                        <i className="ti-location-pin me-1"></i>
                                                        {value.location?.name || "View Location"}
                                                    </span>
                                                </h2>
                                            </div>



                                            <div className="card-body p-0">
                                                <ul className="memberlist mt-4 mb-2 ms-0 d-inline-block">
                                                    {joinedUserPics
                                                        .sort(() => Math.random() - 0.5) // Shuffle the array randomly
                                                        .slice(0, 5) // Select the first 4 random images
                                                        .map((pic, idx) => (
                                                            <li key={idx}>
                                                                <a href="/EventPost">
                                                                    <img
                                                                        src={pic}
                                                                        alt={`user-${idx}`}
                                                                        style={{
                                                                            width: "35px",
                                                                            height: "35px",
                                                                            borderRadius: "50%",
                                                                            objectFit: "cover",
                                                                        }}
                                                                        className="d-inline-block"
                                                                    />
                                                                </a>
                                                            </li>
                                                        ))}

                                                    {joinedUserPics.length > 4 && (
                                                        <li className="last-member">
                                                            <a
                                                                href={`/eventpost/${value.slug}`}
                                                                className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center"
                                                            >
                                                                +{joinedUserPics.length - 4}
                                                            </a>
                                                        </li>
                                                    )}
                                                </ul>

                                                <a
                                                    href={`/eventpost/${value.slug}`}
                                                    className="font-xsssss fw-700 ps-3 pe-3 lh-32 float-right mt-4 text-uppercase rounded-3 ls-2 bg-success d-inline-block text-white me-1"
                                                >
                                                    Join Event
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <Copyright />
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />
        </Fragment>
    );
};

export default Event;
