import React, { Fragment } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Story from "../components/Story";
import CategoryList from "../components/CategoryList"
import Slider from "react-slick";
import "./custom.css";
import Friendsilder from "../components/Friendsilder";
import TestimonialSlider from "../components/TestimonialSlider";
import Experience from "../components/Experience";
import Eventslist from "../components/EventList";
import Copyright from "../components/Copyright";
import SEO from "../components/Seo";
import { Link, useLocation } from 'react-router-dom';
const Home = () => {
  <SEO
  title="Home - tapcoe"
  description="Explore tapcoe's latest events and contests. Participate now and win amazing prizes!"
  url="https://tapcoe.com/"
  image="https://picvot.com/assets/home-og-image.webp"
/>
  
  // const sliderStyle = {
  //   paddingRight: "20px !important",
  // };

  const shopSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 3000, // Time in milliseconds between slides (3 seconds)
  };
  const categories = [
    {
      _id: "6795111bd68da8e5229915d7",
      name: "Brands",
      slug: "brands",
      icon: "https://media.picvot.com/uploads/category/Brands.webp",
    },
    {
      _id: "67951166d68da8e5229915da",
      name: "College",
      slug: "college",
      icon: "https://media.picvot.com/uploads/category/College.webp",
    },
    {
      _id: "6795118ed68da8e5229915dd",
      name: "Public",
      slug: "public",
      icon: "https://media.picvot.com/uploads/category/Public.webp",
    },
  ];

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat /> */}

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <div className="card w-100 border-0 shadow-none rounded-xxl border-0 mb-3 overflow-hidden">
                  <div className="col-lg-12">
                    <div className="banner-wrapper bg-greylight overflow-hidden rounded-3 shop-slider">
                      <Slider {...shopSettings}>
                        {/* <div className="style1 d-flex align-items-center bg-cyan">
                          <div className="row">
                            <div className="col-12">
                              <img
                                src="https://media.picvot.com/uploads/Banner/Best%20Diwali%20Moments"
                                alt="tapcoe Banner"
                                className="img-fluid p-md-2 p-1"
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="style1 d-flex align-items-center bg-cyan">
                          <div className="row">
                            <div className="col-12">
                              <img
                                src="/assets/images/bn1.png"
                                alt="tapcoe Banner"
                                className="img-fluid p-md-2 p-1"
                              />
                            </div>
                          </div>
                        </div>

                       
                        <div className="style1 d-flex align-items-center bg-cyan">
                          <div className="row">
                            <div className="col-12">
                              <img
                                src="/assets/images/bn2.png"
                                alt="tapcoe Banner"
                                className="img-fluid p-md-2 p-1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="style1 d-flex align-items-center bg-cyan">
                          <div className="row">
                            <div className="col-12">
                              <img
                                src="/assets/images/bn3.png"
                                alt="tapcoe Banner"
                                className="img-fluid p-md-2 p-1"
                              />
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
              {/* <CategoryList categories={categories} /> */}
              <div className="container font-sm text-center text-white p-4 rounded">
              <div className="row">
            {categories.map((category) => (
                <div className="col-lg-4 pe-2 ps-2" key={category._id}>
                    <Link
                        to={`/events/${category.slug}`}
                        className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                        style={{ background: `#e5f6ff` }}
                    >
                        <div className="card-body d-flex p-0 align-items-center justify-content-center">
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <div>
                                    <h4 className="text-primary font-xl fw-700">
                                        {category.name}
                                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                            Explore {category.name} Contest
                                        </span>
                                    </h4>
                                </div>
                                <div>
                                    <img
                                        src={category.icon}
                                        alt={category.name}
                                        className="btn-round-lg"
                                        style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
        </div>
              {/* <Eventslist  /> */}
              <Story />
                      
              <Experience />

              <div className="col-lg-12">
                <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                  <div className="row">
                    <div className="col-lg-12 mt-4">
                      <TestimonialSlider />
                    </div>
                  </div>
                </div>
              </div>

              <Copyright />


            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />

    </Fragment>


  );
};

export default Home;
