import axios from 'axios';

const API_URL =  "https://api.picvot.com/api/v1";

// Get events API
export const getEvents = async (slug) => {
  try {
    //const token = localStorage.getItem('token'); // Get the token from localStorage
    const response = await axios.get(`${API_URL}/events/all/${slug}`);
    console.log(response,"0")
    return response.data;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

export const getEventsHome = async () => {
  try {
    //const token = localStorage.getItem('token'); // Get the token from localStorage
    const response = await axios.get(`${API_URL}/events/homeevents`);
    console.log(response,"0")
    return response.data;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

// Other protected APIs like voting, comments can also be structured similarly
