import React, { Component, Fragment } from "react";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import SEO from "../components/Seo";
import Copyright from "../components/Copyright";
class Advertisewithus extends Component {
  render() {
    <SEO
        title="Advertise with Us - Promote Your Brand on tapcoe"
        description="Reach a highly engaged audience by advertising with tapcoe. Partner with us to showcase your brand, sponsor events, and connect with active participants."
        keywords="advertise with tapcoe, brand promotion, sponsor events, advertising platform, reach audience, marketing opportunities"
        url="https://tapcoe.com/advertisewithus"
        image="https://tapcoe.com/assets/advertise-og-image.webp"
      />
    return (
      <Fragment>
        <Header />
        <Leftnav />
        {/* <Rightchat /> */}

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <div className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center"
                    style={{ backgroundImage: `url("/assets/images/help-bg.png")` }}>
                    <div className="card-body p-md-5 p-4 text-center"
                      style={{ backgroundColor: 'rgba(0,85,255,0.8)' }}>
                      <h2 className="fw-700 display2-size text-white display2-md-size lh-2">Welcome to the tapcoe!</h2>
                    </div>
                  </div>

                  <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
                    <div className="card-body">
                      <div className="mb-4">
                        <h5 className="fw-600 mb-3">Advertise with Us</h5>
                        <p>
                          If you are looking to reach a wider audience and promote your products or services to a community of engaged and active individuals, our online voting-based event hosting website is the perfect platform for you. We offer a range of advertising options to help you reach your target audience and drive meaningful engagement and conversions.
                        </p>

                        <p>
                          <strong>Benefits of Advertising with Us:</strong>
                        </p>

                        <p>
                          - Reach a targeted audience: Our platform attracts a diverse range of individuals who are interested in creating positive change and participating in meaningful conversations and decisions. By advertising with us, you can reach a targeted audience of individuals who are interested in your products or services.
                        </p>

                        <p>
                          - Increase engagement and conversions: Our platform offers a range of features to facilitate engagement and decision-making, including live polls, surveys, and online voting. By advertising with us, you can drive engagement and conversions by providing your target audience with opportunities to participate and make informed decisions.
                        </p>

                        <p>
                          - Access real-time analytics and reporting: Our platform provides real-time analytics and reporting on user engagement and voting results, so you can track the effectiveness of your advertising campaigns and make informed decisions about your marketing strategies.
                        </p>

                        <p>
                          - Customize your advertising campaigns: We offer a range of advertising options to suit your needs and budget, including banner ads, sponsored posts, email newsletters, and more. You can customize your campaigns to align with your branding and messaging, and reach your target audience in the most effective way possible.
                        </p>

                        <p>
                          We are committed to helping our advertisers reach their goals and achieve meaningful results. If you are interested in advertising with us, please contact us today to learn more about our advertising options and pricing. We look forward to working with you!
                        </p>
                      </div>



                      <div className="mb-4">
                        <h5 className="fw-600 mb-3">Banner/Text Advertisement</h5>
                        <p>
                          Your company's banner/text ads will be prominently displayed on our website. These ads can be customized to suit your specific requirements, allowing users to easily explore and learn more about your products and services.
                        </p>
                      </div>



                      <div className="mb-4">
                        <h5 className="fw-600 mb-3">Event Sponsors / Product Campaign</h5>
                        <p>
                          We offer a unique and compelling opportunity to efficiently advertise your products through event sponsorship and dedicated product campaigns. Our platform hosts events in various formats and categories. If you're looking to promote your product in a way that best aligns with the event category and create an effective campaign to expand your customer base, we are here to assist you in every possible way.
                        </p>
                      </div>

                      <div className="mb-4">
                        <h5 className="fw-600 mb-3">Advertising at the Event Location</h5>
                        <p>
                          We provide a unique opportunity for our sponsors and advertisers to showcase their products directly at the heart of events. Whether it's college festivals, organizational programs, or various other event hosting locations, we offer a chance to promote your product amidst the respective crowd. Posters and banners strategically placed at the venue will be displayed alongside content from sponsors and advertisers.
                        </p>
                        <p>
                          In addition to on-site visibility, we extend effective marketing opportunities, including email promotions sent on your company's behalf and coupon/product distribution. This distribution includes your company's coupons or products, ensuring direct engagement with event attendees.
                        </p>
                        <p>
                          If you're an event/show organizer seeking a large audience, consider promoting it through our platform. We also welcome businesses and brands looking to reach a vast global audience. Please feel free to contact us for more information.
                        </p>
                      </div>




                    </div>
                  </div>
                </div>
                <div className="mt-2">

                  <Copyright />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter />
      </Fragment>
    );
  }
}

export default Advertisewithus;
