import React from 'react';

const Experience = () => {
  return (
    <div className="container">
      <div className="col-lg-12">
        <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
          <div className="row">
            <div className="col-lg-6">
              <img
                src="/assets/images/howitworks.jpeg"
                alt="banner"
                className="w-100"
              />
            </div>
            <div className="col-lg-6 ps-lg-5">
              <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">
                How It Works
              </h2>
              <p className="font-xssss fw-500 text-grey-500 lh-26">
                Register with your Indian WhatsApp number, verify the OTP, join exciting events, and compete to win contests with amazing rewards.
              </p>
              <a
                href="/about-us"
                className="btn w200 border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-3 d-inline-block font-xssss"
              >
                See More
              </a>
            </div>

          </div>
        </div>
      </div>

      <div className="col-lg-12">
    <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
        <div className="row">
            
            <div className="col-lg-6 ps-lg-5">
                <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">
                    Happy Hour Voting
                </h2>
                <p className="font-xssss fw-500 text-grey-500 lh-26">
                Users can vote multiple times in public events, with a gap of 5 minutes between votes, except during "Happy Hours," when they can vote freely
                      </p>
            </div>
            
            <div className="col-lg-6">
                <img
                    src="https://media.picvot.com/uploads/promotion/Happy_Hours-vote%20(200%20x%20100%20px).png"
                    alt="Happy Hour Voting Banner"
                    className="w-100"
                    style={{ maxHeight: '200px', objectFit: 'cover' }}
                />
            </div>

        </div>
    </div>
</div>


      <div className="col-lg-12">
        <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
          <div className="row">
            <div className="col-lg-12 mt-4">
              <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">
                EXPERIENCE PicVot
              </h2>
              <p className="font-xssss fw-500 text-grey-500 lh-26">
                Are you a brand or an organization that wants to boost the engagement in the most creative way? Engage your users through our events in different categories. Easily create interesting contests and events that would help your brand to reach the masses and create zeal and enthusiasm amongst them.
              </p>
              <h2 className="display1-size d-block mb-2 text-grey-900 fw-700 mt-4">
                REWARDS
              </h2>
              <p className="font-xssss fw-500 text-grey-500 lh-26">
                There is always a reward for the winners of the competitions hosted on PicVot, so get exciting rewards and enjoy the event with all the fun elements and enthusiasm.
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
