import React, { Fragment, useState, useEffect, useContext } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import { useParams } from "react-router-dom";
import ProfilecardThree from '../components/ProfilecardThree';
import Events from '../components/Events';
import Postview from '../components/Postview';
import Load from '../components/Load';
import Copyright from "../components/Copyright";
import { getProfileDetails ,getPublicProfileDetails} from '../api/getProfileDetails'; // Assuming you have this API function
import { AuthContext } from "../context/AuthContext";
import Loader from "../components/Loader";
const Userpage = () => {
    const { user } = useContext(AuthContext);
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { username } = useParams(); // Get the username from the URL

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                setLoading(true);

                if (username) {
                    // If username is available in the URL, fetch the public profile
                    const publicProfileData = await getPublicProfileDetails(username);
                    setProfile(publicProfileData);
                } else {
                    // If no username is in the URL, fetch profile using the token
                    const token = localStorage.getItem("token");
                    if (!token) {
                        setError("No token found, please log in.");
                        return;
                    }
                    const privateProfileData = await getProfileDetails(token);
                    setProfile(privateProfileData);
                }
            } catch (err) {
                setError("Failed to fetch profile.");
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [username]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }



    if (error) {
        return <p>{error}</p>; // Show error message
    }

    return (
        <Fragment>
            <Header />
            <Leftnav />
            {/* <Rightchat /> */}

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="row">
                                <div className="col-xl-12 mb-3">
                                    <ProfilecardThree
                                        supporter={profile?.profile?.totalSupporters}
                                        about={profile?.profile?.about}
                                        profilePic={profile?.profile?.profilePic}
                                        coverPic={profile?.profile?.coverPic}
                                        name={profile?.profile?.name}
                                    />
                                </div>

                                <div className="col-xl-4 col-xxl-3 col-lg-4 ">
                                    <Events joinedEvents={profile?.joinedEvents} />
                                </div>

                                <div className="col-xl-8 col-xxl-9 col-lg-8">
                                    {profile?.joinedEvents?.length ? (
                                        profile.joinedEvents.map((event, index) => {
                                            const { post } = event; // Destructure post from event
                                            const hasValidPost = post?.postId; // Check if postId exists

                                            if (!hasValidPost) {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden"
                                                    >
                                                        <h4 className="fw-700 text-grey-900 font-xssss mt-2">
                                                            You have not posted anything yet.
                                                        </h4>
                                                    </div>
                                                );
                                            }

                                            return (
                                                <Postview
                                                    key={index}
                                                    id={event.eventId}
                                                    postid={post.postId}
                                                    vote={post.votes || 0}
                                                    postvideo=""
                                                    postimage={post.postImageUrl || "/assets/images/default-post.webp"}
                                                    avatar={user?.profilePic || "/assets/images/default-profile.webp"}
                                                    user={profile?.profile?.name || "Anonymous"}
                                                    
                                                    time={post.postCreatedAt || "N/A"}
                                                />
                                            );
                                        })
                                    ) : (
                                        <div className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
                                            <h4 className="fw-700 text-grey-900 font-xssss mt-2">
                                                You have not posted anything yet.
                                            </h4>
                                        </div>
                                    )}
                                </div>

                                <div className="mt-2">
                                    <Copyright />
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />
        </Fragment>
    );
};

export default Userpage;
