import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const CategorySlider = ({ categories = [] }) => {
  // Ensure categories is an array
  if (!Array.isArray(categories)) {
    console.error("categories should be an array");
    return null; // Return null if it's not an array
  }

  const sliderSettings = {
    arrows: false,
    dots: false,
    infinite: false, // Disabled infinite scrolling
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
  };

  return (
    <Slider {...sliderSettings}>
      {categories.map((category, index) => (
        <Link
          to={`/events/${category.slug}`}
          key={index}
          style={{
            display: "inline-block",
            width: "150px",
            marginRight: "10px",
            border: "1px solid #ddd",
            borderRadius: "15px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            textDecoration: "none",
            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             // height: "100px",
             // backgroundColor: "#f9f9f9",
            }}
          >
            <img
              src={category.icon}
              alt={category.name}
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                objectFit: "cover",
                backgroundColor: "#fff",
                padding: "5px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            />
          </div>
          <div
            style={{
              padding: "10px",
              textAlign: "center",
            }}
          >
            <h4
              style={{
                margin: "0",
                fontSize: "14px",
                fontWeight: "600",
                color: "#333",
              }}
            >
              {category.name}
            </h4>
          </div>
        </Link>
      ))}
    </Slider>
  );
};

export default CategorySlider;
