

import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Darkbutton from "../components/Darkbutton";
import Darkbuttonm from "../components/Darkbuttonm";
import { AuthContext } from "../context/AuthContext";
const Header = () => {
  const { user } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isNoti, setIsNoti] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);
  const toggleActive = () => setIsActive(!isActive);
  const toggleisNoti = () => setIsNoti(!isNoti);

  const navClass = isOpen ? " nav-active" : "";
  const buttonClass = isOpen ? " active" : "";
  const searchClass = isActive ? " show" : "";
  const notiClass = isNoti ? " show" : "";
  console.log(user, "hai")
  return (
    <div className="nav-header bg-white shadow-xs border-0">
      <div className="nav-top d-flex align-items-center justify-content-between px-3">
    {/* Dark Mode Button on the Left */}
    {/* <div className="d-flex align-items-center ">
        <Darkbuttonm />
        
    </div> */}
    


    {/* Logo in the Center */}
    <Link to="/" className="d-flex align-items-center">
        <i className="feather-camera text-primary display2-size" style={{ fontSize: '2rem' }}></i>
        <span
            className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0"
            style={{ fontSize: '2rem' }}
        >
            tapcoe
        </span>
    </Link>

    {/* Hamburger Menu on the Right */}
    <button
        onClick={toggleOpen}
        className={`nav-menu me-0 ms-auto ${buttonClass}`}
        style={{ fontSize: '1.5rem' }}
    ></button>
</div>


      <form action="#" className="float-left header-search ms-3">
        <div className="form-group mb-0 icon-input">
          <i className="feather-search font-sm text-grey-400"></i>
          <input
            type="text"
            placeholder="Start typing to search.."
            className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg"
          />
        </div>
      </form>
      <NavLink activeClassName="active" to="/" className="p-2 text-center ms-3 menu-icon center-menu-icon">
        <i className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
      </NavLink>
      <NavLink activeClassName="active" to="/events" className="p-2 text-center ms-3 menu-icon center-menu-icon">
        <i className="feather-calendar font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500"></i>
      </NavLink>
      <NavLink activeClassName="active" to="/feed" className="p-2 text-center ms-0 menu-icon center-menu-icon">
        <i className="feather-zap font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
      </NavLink>
      <NavLink activeClassName="active" to="/winners" className="p-2 text-center ms-0 menu-icon center-menu-icon">
        <i className="feather-trending-up font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
      </NavLink>

      <NavLink activeClassName="active" to="/profile" className="p-2 text-center ms-0 menu-icon center-menu-icon">
        <i className="feather-user font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
      </NavLink>


      <span
        className={`p-2 pointer text-center ms-auto menu-icon ${notiClass}`}
        id="dropdownMenu3"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={toggleisNoti}
      ></span>


      {/* <Link to="/defaultsettings" className="p-0 ms-3 menu-icon">
        <img src="/assets/images/user.png" alt="user" className="w40 mt--1" />
      </Link> */}
      <Link to="/accountsettings" className="p-0 ms-3 menu-icon">
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: user?.profilePic ? "transparent" : "blue", // Set background color only if no profile pic
            padding: user?.profilePic ? "0" : "5px", // Add padding only if no profile pic
          }}
        >
          {user?.profilePic ? (
            <img
              src={user.profilePic}
              alt={user.name || "user"} // Fallback for alt text
              className="mt--1"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <span style={{ color: "white", fontWeight: "bold" }}>tapcoe</span> // Set text color to blue
          )}
        </div>



        {/* <img
          src="/assets/images/profile.webp"
          alt="user"
          className="mt--1"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            objectFit: "cover"
          }}
        /> */}
      </Link>

      <nav className={`navigation scroll-bar ${navClass}`}>
        <div className="container ps-0 pe-0">
          <div className="nav-content">
            <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
              <div className="nav-caption fw-600 font-xssss text-grey-500">
                <span>tapcoe </span>Menu
              </div>
              <ul className="mb-1 top-content">
                <li className="logo d-none d-xl-block d-lg-block"></li>
                <li>
                  <Link to="/" className="nav-content-bttn open-font">
                    <i className="feather-home btn-round-md bg-blue-gradiant me-3"></i>
                    <span>Home</span>
                  </Link>
                </li>
                <li>
                  <Link to="/events" className="nav-content-bttn open-font">
                    <i style={{ backgroundColor: 'green' }} className="feather-calendar btn-round-md me-3"></i>
                    <span>Events</span>
                  </Link>
                </li>
                <li>
                  <Link to="/feed" className="nav-content-bttn open-font">
                    <i className="feather-zap btn-round-md bg-red-gradiant me-3"></i>
                    <span>Feed</span>
                  </Link>
                </li>
                <li>
                  <Link to="/winners" className="nav-content-bttn open-font">
                    <i className="feather-trending-up btn-round-md bg-gold-gradiant me-3"></i>
                    <span>Winners</span>
                  </Link>
                </li>
                <li>
                  <Link to="/profile" className="nav-content-bttn open-font">
                    <i className="feather-user btn-round-md bg-primary-gradiant me-3"></i>
                    <span>Profile </span>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
              <div className="nav-caption fw-600 font-xssss text-grey-500">
                <span>More </span>Pages
              </div>
              <ul className="mb-3">
                <li>
                  <Link to="/accountsettings" className="nav-content-bttn open-font">
                    <i className="font-xl text-current feather-settings me-3"></i>
                    <span>Update Profile</span>
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" className="nav-content-bttn open-font">
                    <i className="font-xl text-current feather-info me-3"></i>
                    <span>About Us</span>
                  </Link>
                </li>

                <li>
                  <Link to="/hostevent" className="nav-content-bttn open-font">
                    <i className="font-xl text-current feather-calendar me-3"></i>
                    <span>Host An Event</span>
                  </Link>
                </li>
                <li>
                  <Link to="/campusambassador" className="nav-content-bttn open-font">
                    <i className="font-xl text-current feather-users me-3"></i>
                    <span>Campus Ambassador</span>
                  </Link>
                </li>
                <li>
                  <Link to="/advertisewithus" className="nav-content-bttn open-font">
                    <i className="font-xl text-current feather-video me-3"></i>
                    <span>Advertise With Us</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/careers" className="nav-content-bttn open-font">
                    <i className="font-xl text-current feather-briefcase me-3"></i>
                    <span>Career</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/contact-us" className="nav-content-bttn open-font">
                    <i className="font-xl text-current feather-mail me-3"></i>
                    <span>Contact Us</span>
                  </Link>
                </li>
                <li>
                  <Link to="/faq" className="nav-content-bttn open-font">
                    <i className="font-xl text-current feather-help-circle me-3"></i>
                    <span>FAQ</span>
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className="nav-content-bttn open-font">
                    <i className="font-xl text-current feather-shield me-3"></i>
                    <span>Privacy Policy</span>
                  </Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions" className="nav-content-bttn open-font">
                    <i className="font-xl text-current feather-slash me-3"></i>
                    <span>Terms & Conditions</span>
                  </Link>
                </li>
                <hr style={{ borderTop: "1px solid #ddd", margin: "10px 0" }} />
                <li className="d-flex align-items-center justify-content-between">
                  {/* YouTube */}
                  <a
                    href="https://www.youtube.com/@picvotdotcom"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-content-bttn open-font"
                    style={{ marginRight: "15px" }}
                  >
                    <i className="font-xl text-current feather-youtube me-1"></i>
                    <span></span>
                  </a>

                  {/* Instagram */}
                  <a
                    href="https://www.instagram.com/picvotdotcom"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-content-bttn open-font"
                    style={{ marginRight: "15px" }}
                  >
                    <i className="font-xl text-current feather-instagram me-1"></i>
                    <span></span>
                  </a>

                  {/* LinkedIn */}
                  <a
                    href="https://www.linkedin.com/company/picvotdotcom"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-content-bttn open-font"
                  >
                    <i className="font-xl text-current feather-linkedin me-1"></i>
                    <span></span>
                  </a>
                </li>


              </ul>
            </div>


          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
