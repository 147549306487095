import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { getVoterNamesForPost } from '../api/getVoterNamesForPost';
import LocationComponent from './Location';

const VotingDashboard = ({ postId, eventId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [voterData, setVoterData] = useState([]);
  const [datewiseSeries, setDatewiseSeries] = useState([]);
  const usersPerPage = 5;

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: 'bar',
        height: 250,
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: { enabled: false },
      xaxis: {
        categories: ['0-15', '15-30', '30-60', '60-100'],
        labels: {
          style: {
            colors: '#FFFFFF', // White text for x-axis
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#FFFFFF', // White text for y-axis
            fontSize: '12px',
          },
        },
      },
      title: {
        text: 'Votes by Gender and Age',
        align: 'center',
        style: {
          color: '#FFFFFF', // White title text
          fontSize: '16px',
        },
      },
      legend: {
        labels: {
          colors: '#FFFFFF', // White text for legend (e.g., Male Votes, Female Votes)
        },
      },
      colors: ['#008FFB', '#FF4560'], // Bar colors
    },
    series: [
      {
        name: 'Male Votes',
        data: [10, 20, 30, 40],
      },
      {
        name: 'Female Votes',
        data: [15, 25, 35, 45],
      },
    ],
  });
  
  

  const [dateChartData, setDateChartData] = useState({
    options: {
      chart: {
        type: 'line',
        height: 250,
        toolbar: { show: false },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: '#FFFFFF', // White text for x-axis
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#FFFFFF', // White text for y-axis
            fontSize: '12px',
          },
        },
      },
      title: {
        text: 'Date-Wise Votes',
        align: 'center',
        style: {
          fontSize: '16px',
          color: '#FFFFFF', // White title text
        },
      },
      grid: {
        borderColor: '#FFFFFF', // White grid border
      },
      plotOptions: {
        line: {
          curve: 'smooth',
        },
      },
      dataLabels: { enabled: false },
      colors: ['#008FFB'],
      fill: {
        type: 'solid',
        colors: ['#008FFB'], // Solid blue fill color
      },
      tooltip: { theme: 'dark' }, // Dark tooltip theme for better contrast
    },
    series: [],
  });
  

  useEffect(() => {
    const fetchVoterData = async () => {
      try {
        const data = await getVoterNamesForPost(postId, eventId);

        setVoterData(data.voters || []);
        setChartData((prevData) => ({
          ...prevData,
          series: data.genderSeries || [],
        }));

        const dates = data.datewiseSeries?.map((item) => item.date) || [];
        const totalVotes = data.datewiseSeries?.map((item) => item.totalVotes) || [];

        setDatewiseSeries(data.datewiseSeries || []);
        setDateChartData((prevData) => ({
          ...prevData,
          options: {
            ...prevData.options,
            xaxis: { categories: dates },
          },
          series: [{ name: 'Votes', data: totalVotes }],
        }));
      } catch (error) {
        console.error('Error fetching voter names:', error);
      }
    };

    fetchVoterData();
  }, [postId, eventId]);

  const totalPages = Math.ceil(voterData.length / usersPerPage);
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = voterData.slice(indexOfFirstUser, indexOfLastUser);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const getTimeAgo = (postCreatedAt) => {
    const now = new Date();
    const postTime = new Date(postCreatedAt);
    const timeDifference = now - postTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return `just now`;
  };

  return (
    <div style={{ marginTop: '20px' }}>
      {/* Age-Gender Votes Chart */}
      <div className="card shadow-sm border border-primary p-4 mb-3" style={{ flex: 1, borderRadius: '12px', marginRight: '20px' }}>
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={250}
        />
      </div>

      {/* Date-Wise Votes Chart */}
      {datewiseSeries.length > 0 ? (
        <div className="card shadow-sm border border-primary p-4 mb-3" style={{ flex: 1, borderRadius: '12px',marginRight: '20px' }}>
    
          <Chart
            options={dateChartData.options}
            series={dateChartData.series}
            type="line"
            height={250}
          />
        </div>
      ) : (
        <p className="text-center text-muted">No date-wise voting data available.</p>
      )}

      {/* Voters List */}
      <div className="card shadow-sm border border-primary p-4 mb-3" style={{ flex: 1, borderRadius: '12px', height: '350px', overflowY: 'auto' }}>
        <h5 className="mb-3 text-center" style={{ color: '#212529' }}>Users Who Voted</h5>
        <ul className="list-group" style={{ maxHeight: '200px', overflowY: 'auto', padding: 0 }}>
          {currentUsers.map((user, index) => (
            <li key={index} className="list-group-item d-flex align-items-center" style={{ padding: '10px 15px' }}>
              <img
                src={user.profilePic}
                alt="User Avatar"
                style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
              />
              <div className="flex-grow-1">
                <span>{user.name}</span>
                <LocationComponent location={user.registerLocation} />
              </div>
              <div className="d-flex align-items-center">
                <span className="badge bg-primary" style={{ marginRight: '22px' }}>{getTimeAgo(user.votedAt)}</span>
                <span className="badge bg-secondary">+{user.totalVoteValue}</span>
              </div>
            </li>
          ))}
        </ul>

        {/* Pagination Controls */}
        <div className="d-flex justify-content-between mt-3">
          <button className="btn btn-outline-primary" onClick={handlePrev} disabled={currentPage === 1}>
            Previous
          </button>
          <button className="btn btn-outline-primary" onClick={handleNext} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default VotingDashboard;
