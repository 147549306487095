import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Appfooter = () => {
    const location = useLocation(); // Get the current path

    // Get theme from localStorage or default to 'light'
    const theme = localStorage.getItem('theme') || 'light';
    const isDarkTheme = theme === 'dark';

    // Define colors based on theme
    const activeColor = isDarkTheme ? '#ffffff' : '#00ff00'; // Active color: Green for light theme, white for dark theme
    const inactiveColor = isDarkTheme ? '#bbb' : '#333'; // Inactive color: subtle shades
    const iconActiveColor = isDarkTheme ? '#ffffff' : '#333333'; // Active icon color: dark gray for light theme and white for dark theme
    const iconInactiveColor = isDarkTheme ? '#bbb' : '#333'; // Inactive icon color: subtle shades
    const textActiveColor = isDarkTheme ? '#ffffff' : '#000000'; // Active text color (dark theme = white, light theme = black)
    const textInactiveColor = isDarkTheme ? '#bbb' : '#333'; // Inactive text color

    const getActiveStyle = (path) => ({
        color: location.pathname === path ? textActiveColor : textInactiveColor,
        borderBottom: location.pathname === path ? `3px solid ${activeColor}` : 'none',
        padding: '12px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: '10px', // Updated font size for all spans
    });

    const getIconStyle = (path) => ({
        color: location.pathname === path ? iconActiveColor : iconInactiveColor,
    });

    return (
        <div
            className={`app-footer d-block d-md-none border-0 shadow-lg 
            d-flex justify-content-around`}
        >
            <Link to="/" className="nav-content-bttn nav-center" style={getActiveStyle('/')}>
                <i
                    className="feather-home bg-greylight btn-round-xs theme-dark-bg text-grey-600"
                    style={getIconStyle('/')}
                ></i>
                <span>Home</span>
            </Link>
            <Link to="/events" className="p-2 text-center ms-3 menu-icon" style={getActiveStyle('/events')}>
                <i
                    className="feather-calendar bg-greylight btn-round-xs theme-dark-bg text-grey-600"
                    style={getIconStyle('/events')}
                ></i>
                <span>Events</span>
            </Link>
            <Link to="/feed" className="nav-content-bttn" style={getActiveStyle('/feed')}>
                <i
                    className="feather-zap bg-greylight btn-round-xs theme-dark-bg text-grey-600"
                    style={getIconStyle('/feed')}
                ></i>
                <span>Feed</span>
            </Link>
            <Link to="/winners" className="nav-content-bttn" style={getActiveStyle('/winners')}>
                <i
                    className="feather-trending-up bg-greylight btn-round-xs theme-dark-bg text-grey-600"
                    style={getIconStyle('/winners')}
                ></i>
                <span>Winners</span>
            </Link>
            <Link to="/profile" className="nav-content-bttn" style={getActiveStyle('/profile')}>
                <i
                    className="feather-user bg-greylight btn-round-xs theme-dark-bg text-grey-600"
                    style={getIconStyle('/profile')}
                ></i>
                <span>Profile</span>
            </Link>
        </div>
    );
};

export default Appfooter;
