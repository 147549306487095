import React, { Component, Fragment } from "react";
import axios from "axios"; // Import axios

import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Copyright from "../components/Copyright";
import SEO from "../components/Seo";
class Campusambassador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      contactNumber: "",
      details: "",
      errorMessage: "",
      successMessage: "",
    };
  }

  // Handle input changes
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  // Handle form submission
  handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form reload

    const { email, contactNumber, details } = this.state;

    try {
      const response = await axios.post("https://api.tapcoe.com/api/v1/campusambasdor", {
        email,
        contactNumber,
        details,
      });

      // Handle success
      this.setState({
        successMessage: "Form submitted successfully!",
        errorMessage: "",
        email: "",
        contactNumber: "",
        details: "",
      });
    } catch (error) {
      // Handle error
      this.setState({
        errorMessage: error.response?.data?.message || "Submission failed. Please try again.",
        successMessage: "",
      });
    }
  };

  render() {
    const { email, contactNumber, details, errorMessage, successMessage } = this.state;
<SEO
        title="Become a Campus Ambassador - Represent tapcoe on Your Campus"
        description="Join the tapcoe Campus Ambassador Program to represent us on your campus, promote exciting events, and develop leadership skills. Earn rewards, build networks, and grow with us!"
        keywords="campus ambassador program, tapcoe ambassador, student leadership, event promotion, networking, earn rewards, tapcoe opportunities"
        url="https://tapcoe.com/campusambassador"
        image="https://tapcoe.com/assets/campus-ambassador-og-image.webp"
      />
    return (
      <Fragment>
        <Header />
        <Leftnav />
        {/* <Rightchat /> */}

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <div
                    className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center"
                    style={{
                      backgroundImage: `url("/assets/images/help-bg.png")`,
                    }}
                  >
                    <div
                      className="card-body p-md-5 p-4 text-center"
                      style={{ backgroundColor: "rgba(0,85,255,0.8)" }}
                    >
                      <h2 className="fw-700 display2-size text-white display2-md-size lh-2">
                        Welcome to the tapcoe!
                      </h2>
                    </div>
                  </div>

                  <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
                    <div className="card-body">
                      <div className="mb-4">
                        <h5 className="fw-600 mb-3">Campus Ambassador</h5>

                        <p>
                          We are excited to offer a campus ambassador program for students who are
                          passionate about creating positive change and promoting participatory events
                          in their communities. Our campus ambassadors play a crucial role in spreading
                          the word about our online voting-based event hosting website and empowering
                          their peers to participate in meaningful conversations and decisions.
                          As a campus ambassador, you will receive exclusive access to our platform,
                          training and support from our team, and a range of resources to help you promote
                          our platform and host successful events on your campus. You will also have the
                          opportunity to connect with other like-minded students from around the world
                          and build your network and leadership skills.
                          Here are some of the benefits of being a campus ambassador:
                          Gain valuable experience: As a campus ambassador, you will have the opportunity
                          to develop your leadership, communication, and event planning skills. You will
                          also gain hands-on experience in marketing, social media management, and
                          community outreach.
                          Make a difference: As a campus ambassador, you will be promoting a platform that
                          facilitates positive change and creates more inclusive and participatory event
                          experiences. You will be empowering your peers to have their voices heard and
                          make a difference in their communities.
                          Build your network: As a campus ambassador, you will have the opportunity to
                          connect with other like-minded students from around the world and build your
                          network. You will also have access to our team of experienced event professionals
                          and developers, who can provide you with guidance and support.
                          Earn rewards: As a campus ambassador, you will have the opportunity to earn
                          rewards for your efforts, including exclusive access to events, merchandise,
                          and even scholarship opportunities.
                          We are looking for passionate and motivated students who are committed to creating
                          positive change in their communities. If you are interested in becoming a campus
                          ambassador for our online voting-based event hosting website, please contact us
                          today. We look forward to hearing from you.
                        </p>

                      </div>
                    </div>
                  </div>

                  {/* Form Section */}
                  <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
                    <div className="card-body">
                      <h5 className="fw-600 mb-3" style={{ color: "rgba(0,85,255,0.8)" }}>
                        Connect for Hosting an Event
                      </h5>
                      <form onSubmit={this.handleSubmit}>
                        {/* Email Field */}
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label" style={{ color: "rgba(0,85,255,0.8)" }}>
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={email}
                            onChange={this.handleChange}
                            placeholder="Enter your email"
                            required
                          />
                        </div>

                        {/* Contact Number Field */}
                        <div className="mb-3">
                          <label htmlFor="contactNumber" className="form-label" style={{ color: "rgba(0,85,255,0.8)" }}>
                            Contact Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="contactNumber"
                            value={contactNumber}
                            onChange={this.handleChange}
                            placeholder="Enter your contact number"
                            required
                          />
                        </div>

                        {/* Details Field */}
                        <div className="mb-3">
                          <label htmlFor="details" className="form-label" style={{ color: "rgba(0,85,255,0.8)" }}>
                            Why do you want to become a campus ambassador?
                          </label>
                          <textarea
                            className="form-control"
                            id="details"
                            rows="3"
                            value={details}
                            onChange={this.handleChange}
                            placeholder="Explain in detail"
                            required
                          ></textarea>
                        </div>
                        {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}

                        {/* Submit Button */}
                        <div className="d-grid gap-2">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </form>

                      {/* Display Messages */}

                    </div>
                  </div>

                </div>
                <div className="mt-2">

                  <Copyright />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter />
      </Fragment>
    );
  }
}

export default Campusambassador;
