import React, { useEffect, useState, useContext } from 'react';
import { getCommentDetails, sendComment, sendReply } from '../api/getComments'; 
import { useAuth } from "../context/AuthContext"; // Ensure your AuthContext is set up properly

const CommentSection = ({ postId, eventId }) => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [replyText, setReplyText] = useState('');
    const [replyingTo, setReplyingTo] = useState(null);
    const { isLoggedIn, token } = useAuth(); // Using token and login state from AuthContext

    // Helper to display relative time
    const getRelativeTime = (date) => {
        const now = new Date();
        const secondsAgo = Math.floor((now - new Date(date)) / 1000);
        if (secondsAgo < 60) return `${secondsAgo} s`;
        if (secondsAgo < 3600) return `${Math.floor(secondsAgo / 60)} m`;
        if (secondsAgo < 86400) return `${Math.floor(secondsAgo / 3600)} h`;
        if (secondsAgo < 604800) return `${Math.floor(secondsAgo / 86400)} d`;
        if (secondsAgo < 2419200) return `${Math.floor(secondsAgo / 604800)} w`;
        if (secondsAgo < 29030400) return `${Math.floor(secondsAgo / 2419200)} mo`;
        return `${Math.floor(secondsAgo / 29030400)} yr`;
    };

    // Fetch comments on component mount
    useEffect(() => {
        const fetchComments = async () => {
            try {
                const data = await getCommentDetails(postId); // Fetch comments from API
                setComments(data);
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };
        fetchComments();
    }, [postId]);

    // Handle new comment input
    const handleCommentChange = (e) => setNewComment(e.target.value);

    // Handle reply input change
    const handleReplyChange = (e) => setReplyText(e.target.value);

    // Submit new comment
    const handleCommentSubmit = async () => {
        try {
            if (newComment.trim()) {
                const response = await sendComment(postId, eventId, newComment);
                setComments((prevComments) => [...prevComments, response]);
                setNewComment(''); // Clear input field after submitting
            }
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
    };

    // Submit a reply to a comment
    const handleReplySubmit = async (parentCommentId) => {
        console.log(parentCommentId)
        try {
            if (replyText.trim()) {
                const response = await sendReply(postId,eventId,  replyText ,parentCommentId);
                setComments((prevComments) =>
                    prevComments.map(comment =>
                        comment._id === parentCommentId
                            ? { ...comment, replies: [...(comment.replies || []), response] }
                            : comment
                    )
                );
                setReplyingTo(null); // Close reply input after submission
                setReplyText('');
            }
        } catch (error) {
            console.error('Error submitting reply:', error);
        }
    };

    return (
        <div className="col-lg-4">
        <div
            className="card w-100 shadow-sm rounded-xxl border-0 p-4 mb-3"
            style={{
                marginTop: '20px',
                height: comments.length > 0 ? 'auto' : '150px', // Adjust height if no comments
                maxHeight: '580px', // Set maximum height
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <h5 className="fw-700 text-grey-900 mb-4">Comments</h5>
    
            <div style={{ flex: 1, overflowY: 'auto' }}>
                {comments.length > 0 ? (
                    comments.map((comment, index) => (
                        <div
                            key={index}
                            className="d-flex flex-column mb-3"
                            style={{
                                padding: '10px',
                                borderBottom: '1px solid #efefef',
                            }}
                        >
                            <div className="d-flex">
                                <figure className="avatar me-3">
                                    <img
                                        src={comment?.userDetails?.profilePic || '/assets/images/default-avatar.png'}
                                        alt="avatar"
                                        className="shadow-sm rounded-circle"
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </figure>
                                <div style={{ flex: 1 }}>
                                    <h6
                                        className="fw-700 text-grey-900 font-xsss mb-1"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <span>
                                            {comment?.userDetails?.name}
                                            <span className="font-xssss text-grey-500 ms-2">
                                                {getRelativeTime(comment.createdAt)}
                                            </span>
                                        </span>
                                    </h6>
                                    <p
                                        className="font-xssss text-grey-500"
                                        style={{
                                            margin: 0,
                                            lineHeight: '1.2',
                                        }}
                                    >
                                        {comment.commentText}
                                    </p>
                                    <button
                                        className="btn btn-link text-primary p-0 mt-1"
                                        onClick={() => setReplyingTo(comment._id)}
                                        style={{
                                            fontSize: '12px',
                                        }}
                                    >
                                        Reply
                                    </button>
    
                                    {comment.replies &&
                                        comment.replies.map((reply, idx) => (
                                            <div
                                                key={idx}
                                                className="d-flex mt-2"
                                                style={{
                                                    marginLeft: '40px',
                                                }}
                                            >
                                                <figure className="avatar me-3">
                                                    <img
                                                        src={
                                                            reply?.userDetails
                                                                ?.profilePic ||
                                                            '/assets/images/default-avatar.png'
                                                        }
                                                        alt="avatar"
                                                        className="shadow-sm rounded-circle"
                                                        style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                </figure>
                                                <div style={{ flex: 1 }}>
                                                    <h6 className="fw-700 text-grey-600 font-xssss mb-1">
                                                        {
                                                            reply?.userDetails
                                                                ?.name
                                                        }
                                                        <span className="font-xssss text-grey-500 ms-2">
                                                            {getRelativeTime(
                                                                reply.createdAt
                                                            )}
                                                        </span>
                                                    </h6>
                                                    <p
                                                        className="font-xssss text-grey-500"
                                                        style={{ margin: 0 }}
                                                    >
                                                        {reply.commentText}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
    
                                    {replyingTo === comment._id && (
                                        <div className="d-flex align-items-center mt-2">
                                            <input
                                                type="text"
                                                className="form-control rounded-pill ps-3 pe-5"
                                                placeholder="Write a reply..."
                                                value={replyText}
                                                onChange={handleReplyChange}
                                                style={{
                                                    backgroundColor:
                                                        'rgba(255, 255, 255, 0.8)',
                                                    color: '#000',
                                                }}
                                            />
                                            <button
                                                className="btn btn-primary btn-sm rounded-pill ms-2"
                                                onClick={() =>
                                                    handleReplySubmit(comment._id)
                                                }
                                            >
                                                Send
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No comments yet.</p>
                )}
            </div>
    
            <div className="d-flex align-items-center mt-4 position-relative">
            <input
    type="text"
    className="form-control rounded-pill ps-3 pe-5"
    placeholder="Add a comment..."
    value={newComment}
    onChange={handleCommentChange}
    style={{
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        color: '#fff', // Text color set to white
    }}
/>

                {isLoggedIn ? (
                    <button
                        className="btn btn-primary btn-sm rounded-pill position-absolute end-0 me-2"
                        onClick={handleCommentSubmit}
                    >
                        Submit
                    </button>
                ) : (
                    <span className="text-danger position-absolute end-0 me-2">
                        Login to comment
                    </span>
                )}
            </div>
        </div>
    </div>
    
    );
};

export default CommentSection;

