import React from 'react';
import Slider from "react-slick";

const Memberslider = ({ memberList = [] }) => {
    // Ensure memberList is an array
    if (!Array.isArray(memberList)) {
        console.error('memberList should be an array');
        return null; // Return null if it's not an array
    }

    const membersettings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: false,
        variableWidth: true,
    };

    return (
        <Slider {...membersettings}>
            {memberList.map((value, index) => (
                <div key={index} className="card w200 d-block border-0 shadow-xss rounded-xxl overflow-hidden mb-3 me-3">
                    <div
                        className="card-body position-relative h100 bg-image-cover bg-image-center"
                        style={{ backgroundImage: `url(${value.coverPicture})` }}
                    ></div>

                    <div className="card-body d-block w-100 ps-4 pe-4 pb-4 text-center">
                        <figure
                            className="avatar overflow-hidden ms-auto me-auto mb-0 mt--6 position-relative"
                            style={{ width: '60px', height: '60px' }} // Adjust the size of the figure
                        >
                            <img
                                src={`${value.profilePicture}`}
                                alt="avatar"
                                className="p-1 bg-white rounded-circle w-100"
                                style={{ width: '100%', height: '100%' }} // Ensure the image fits within the figure
                            />
                        </figure>

                        <div className="clearfix"></div>
                        <h4 className="fw-700 font-xsss mt-2 mb-1">{value.name}</h4>
                        <span className="live-tag mt-2 mb-0 bg-success p-2 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">
    Voted {value.voteCount} People 
</span>

                        <div className="clearfix mb-2"></div>
                    </div>
                </div>
            ))}
        </Slider>
    );
};

export default Memberslider;
