import React, { Component, Fragment } from "react";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Copyright from "../components/Copyright";
class Policy extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <Leftnav />
                {/* <Rightchat /> */}

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row justify-content-center">
                                <div className="col-xl-10">
                                    <div className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center"
                                         style={{backgroundImage: `url("assets/images/help-bg.png")`}}>
                                        <div className="card-body p-md-5 p-4 text-center"
                                             style={{backgroundColor: 'rgba(0,85,255,0.8)'}}>
                                            <h2 className="fw-700 display2-size text-white display2-md-size lh-2">Welcome to the tapcoe!</h2>
                                        </div>
                                    </div>

                                    <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
                                        <div className="card-body">
                                        <div className="mb-4">
  <h5 className="fw-600 mb-3">PRIVACY POLICY</h5>
  <p>
    Engage your users through our events in different categories. Easily create interesting contests and events that would help your brand reach the masses and create zeal and enthusiasm amongst them.
  </p>
  <p>
    tapcoe.COM takes privacy issues very seriously.
  </p>
  <h6 className="fw-600">What This Privacy Policy Covers</h6>
  <p>
    This policy covers how tapcoe.COM treats personal information that tapcoe.COM collects and receives when you visit this Website, and use the Services or access the Content. Personal information is information about you that is personally identifiable like your name, address, email address, or phone number, and that is not otherwise publicly available.
  </p>
  <p>
    This policy does not apply to the practices of companies that tapcoe.COM does not own or control, or to people that are not affiliated with tapcoe.COM.
  </p>
  <h6 className="fw-600">Information Collection and Use</h6>
  <p>
    tapcoe.COM collects personal information when you register with tapcoe.COM, when you visit this Website, and use the Services or access the Content. When you register on this Website, we ask for information such as your name, e-mail address, mailing address, occupation, industry, and personal interests, etc.
  </p>
  <p>
    Once you register with tapcoe.COM and/or Log-in, you are not anonymous to us. tapcoe.COM collects a variety of information about you, including information about your gifts and donations to tapcoe.COM, and your use of the Services and the Content. tapcoe.COM automatically receives and records information on our server logs from your browser, including your IP address, tapcoe.COM cookie information, and the page you request.
  </p>
  <p>
    All the above data and information will be collectively referred to as "Information". tapcoe.COM uses Information for the following general purposes: to customize the content you see, fulfill your requests for Content and Services, improve our services, contact you, conduct research, and provide anonymous reporting for internal and external clients.
  </p>
  <h6 className="fw-600">Information Sharing and Disclosure</h6>
  <p>
    tapcoe.COM does not rent, sell, or share Information with other people or non-affiliated entities except to provide services that you have requested; when we have your permission; or under the circumstances detailed below.
  </p>
  <p>
    tapcoe.COM reserves the right to conduct research including, but not limited to, statistical and other forms of analyses based on the Information. Using such research and analyses and the Information collected from you, tapcoe.COM reserves the right to send you certain communications relating to tapcoe.COM and the Content and Services, such as announcements, administrative messages, and newsletters, without offering you the opportunity to opt-out of receiving them.
  </p>
  <p>
    tapcoe.COM reserves the right to provide the Information to trusted partners who work on behalf of or with tapcoe.COM, within the bounds of confidentiality. tapcoe.COM also reserves the right to disclose the Information when required by law or regulation, or when we believe it is necessary to share Information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of tapcoe.COM's terms of use, or as otherwise required by law.
  </p>
  <p>
    tapcoe.COM reserves the right to publish research and analyses based on the Information in public forums, including magazines, newspapers, brochures, books, and any other form of public disclosure. tapcoe.COM will make every reasonable effort to maintain and protect the privacy of individual members while conducting such research and analyses, and publishing them in a public forum.
  </p>
  <p>
    Please note that if you have any concerns about the above Privacy Policy, you can write to us to express your concerns or delete your registration entry by contacting the admin at support@tapcoe.com.
  </p>
  <p>
    Please note that if you have any concerns about the above Privacy Policy, you can write to us to express your concerns by contacting the Admin.
  </p>
</div>


                                       
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2">

<Copyright  />
</div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popupchat />
                <Appfooter />
            </Fragment>
        );
    }
}

export default  Policy ;
